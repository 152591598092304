import { CollectiveMemberFieldsFragment } from 'graphql/generated'
import { ProposalDelegatorItem } from './ProposalDelegatorItem'
import { without } from 'lodash'

export type DelegationsPayload = {
  delegatorCollectiveUserId: UUID
}

export function ProposalDelegationsList({
  onChange,
  selectedValues,
  memberLists,
  formatWeight
}: {
  selectedValues: UUID[]
  onChange: CallbackWithParam<UUID[]>
  memberLists: CollectiveMemberFieldsFragment[]
  formatWeight: boolean
}) {
  if (!memberLists.length) {
    return null
  }

  return (
    <div className="mb-[30px] p-1 bg-gray-100 rounded-[10px]">
      <p className="font-sans text-[13px] font-light text-gray-700 p-[10px] mb-[10px] border-b-gray-300 border-b-[1px]">{`The DAO members listed below have granted you the ability to vote for them. Their voting power will be added to your chosen vote. Select from your available vote delegations:`}</p>

      <div className="max-h-[300px] overflow-auto bg-gray-100 p-[5px] rounded-[10px]">
        {memberLists.map(collectiveMember => {
          const collectiveUserId = collectiveMember.user.id as UUID

          return (
            <ProposalDelegatorItem
              key={collectiveUserId}
              isChecked={selectedValues.includes(collectiveUserId)}
              member={collectiveMember}
              onChange={isChecked => {
                const newValue = isChecked
                  ? [...selectedValues, collectiveUserId]
                  : without(selectedValues, collectiveUserId)

                onChange(newValue)
              }}
              formatWeight={formatWeight}
            />
          )
        })}
      </div>
    </div>
  )
}
