import cls from 'classnames'
import {
  AnswerInput,
  CollectiveProposalStatus,
  ShowResultsAfter
} from 'graphql/generated'
import { chunk } from 'lodash'
import Button from 'components/Button'
import { useMemo, useState } from 'react'
import { CollectiveProposalQuestion } from './CollectiveProposalQuestion'
import { useCurrentUser } from 'hooks'
import moment from 'moment'
import { faBoxBallot } from '@fortawesome/pro-regular-svg-icons'
import { CollectiveCastVoteModal } from '../modals/CastVoteModal'
import ModalTrigger from '../../common/ModalTrigger'
import { TProposal as TProposalOriginal } from 'components/Collectives/ProposalsTable/ProposalsTable.types'
import { checkHasEmptyRequiredQuestions } from './util'

type TProposal = Omit<TProposalOriginal, 'collective'> & {
  collective: { id: string }
  proposedBy: TProposalOriginal['proposedBy']
}

interface IProps {
  proposal: TProposal
  showingPage: number
  setShowingPage: CallbackWithParam<number>
  limitedView: boolean
  listItemView?: boolean
  children?: React.ReactNode
}

const pageButtonWidth = 140
const showQuestionsPerPage = 10
const limitedViewShowQuestionPerPage = 1

export const ProposalSurveyQuestions: React.FC<IProps> = ({
  proposal,
  showingPage,
  setShowingPage,
  limitedView,
  listItemView,
  children
}) => {
  const viewer = useCurrentUser()

  const proposalActive = proposal.status === CollectiveProposalStatus.ACTIVE
  const proposalEnded =
    proposal.status !== CollectiveProposalStatus.ACTIVE &&
    proposal.status !== CollectiveProposalStatus.PENDING
  const isCreator = proposal.proposedBy.user.platformUser?.id === viewer.id
  const alreadyAnswered = proposal.survey?.isAnswered || false

  const stateNotValidToVote = proposalEnded || (!proposalActive && !isCreator)

  const questions = proposal.survey?.questions || []
  const myAnswers = proposal.survey?.myAnswers || []
  const questionPaginatedBy = limitedView
    ? limitedViewShowQuestionPerPage
    : showQuestionsPerPage
  const questionsPagination = chunk(questions, questionPaginatedBy)

  const showResultsAfter = proposal.survey?.options.showResultsAfter
  const showingResults =
    (showResultsAfter === ShowResultsAfter.AFTER_VOTING && alreadyAnswered) ||
    (showResultsAfter === ShowResultsAfter.AFTER_PROPOSAL_ENDS &&
      proposalEnded) ||
    showResultsAfter === ShowResultsAfter.ALWAYS

  const convertAnswersToInput = () => {
    if (!proposal.survey) {
      return []
    }

    if (!myAnswers.length) {
      return proposal.survey.questions.map(
        (): AnswerInput => ({
          choices: []
        })
      )
    }
    const newAnswersArr: AnswerInput[] = myAnswers.map(a => {
      return {
        choices: a.choices.length
          ? a.choices.map(answer => {
              if (answer.dateChoice) {
                return {
                  dateChoice: moment(answer.dateChoice).toDate()
                }
              }
              return {
                stringChoice: answer.stringChoice || ''
              }
            })
          : []
      }
    })

    return newAnswersArr
  }

  const [answersArr, setAnswersArr] = useState<Array<AnswerInput>>(
    convertAnswersToInput()
  )

  const hasEmptyRequiredQuestions = useMemo(
    () => checkHasEmptyRequiredQuestions(answersArr, proposal),
    [answersArr, proposal]
  )

  const showSubmitButton =
    showingPage === questionsPagination.length - 1 && !stateNotValidToVote
  const answersHaveChanged =
    JSON.stringify(convertAnswersToInput()) !== JSON.stringify(answersArr)
  const canSubmit = !hasEmptyRequiredQuestions && answersHaveChanged

  return (
    <div>
      {!listItemView && (
        <p className="text-gray-400 text-sm font-light pb-2">{`Questions: `}</p>
      )}

      <div className="space-y-4">
        {questionsPagination[showingPage].map((question, i) => {
          const questionIndex = i + showingPage * questionPaginatedBy
          return (
            <div
              className={cls(
                'flex flex-col rounded-lg min-h-100',
                !listItemView && 'p-4 bg-gray-50'
              )}
              key={`question_${i}`}
            >
              <CollectiveProposalQuestion
                isOnlyQuestion={questions.length === 1}
                question={question}
                questionIndex={questionIndex}
                answers={answersArr}
                setAnswersArr={setAnswersArr}
                alreadyAnswered={alreadyAnswered}
                disabled={stateNotValidToVote}
                showingResults={showingResults}
                questionResult={proposal.survey?.surveyResults[questionIndex]}
                limitedView={limitedView}
                linkTitleTo={proposal.publicUrl}
              />
            </div>
          )
        })}
      </div>

      {questionsPagination.length > 1 && (
        <div className="mt-4 mb-1 flex justify-center items-center gap-2 flex-row">
          <div className="text-center">
            <span className="text-sm text-gray-800">
              {`${questionPaginatedBy === 1 ? 'Question ' : 'Page '} ${
                showingPage + 1
              } of ${questionsPagination.length}`}
            </span>
          </div>
        </div>
      )}

      <div className="mt-4 mb-1 flex justify-between items-center gap-2 flex-row">
        {children && <div>{children}</div>}

        {showingPage > 0 ? (
          <Button
            label={`Previous ${
              questionPaginatedBy === 1 ? 'Question' : 'Page'
            }`}
            color="white"
            onClick={() => setShowingPage(showingPage - 1)}
            style={{ width: pageButtonWidth }}
            size={limitedView ? 'xs' : 'md'}
          />
        ) : (
          <div />
        )}

        {showingPage < questionsPagination.length - 1 && (
          <Button
            label={`Next ${questionPaginatedBy === 1 ? 'Question' : 'Page'}`}
            color="white"
            onClick={() => setShowingPage(showingPage + 1)}
            style={{ width: pageButtonWidth }}
            size={limitedView ? 'xs' : 'md'}
          />
        )}

        {showSubmitButton && (
          <ModalTrigger
            trigger={onClick => (
              <Button
                icon={faBoxBallot}
                color={'blue'}
                size={limitedView ? 'xs' : 'md'}
                onClick={onClick}
                disabled={!canSubmit}
              >
                {proposal.myVote ? 'Change Vote' : 'Vote'}
              </Button>
            )}
          >
            <CollectiveCastVoteModal
              proposal={proposal}
              surveyAnswers={answersArr}
            />
          </ModalTrigger>
        )}
      </div>
    </div>
  )
}
