import Popover from 'components/common/Popover'
import { BigNumber, formatNumber } from 'lib/BigInt'
import { CollectiveProposal } from 'graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

export type TProposal = Pick<
  CollectiveProposal,
  'totalVoters' | 'isQuorumReached' | 'quorumNeeded'
>

interface IProps {
  proposal: TProposal
  variant?: 'narrow' | 'normal'
}

function QuorumViz({ proposal }: IProps) {
  const { quorumNeeded } = proposal
  const quorumPercent = Math.min(
    new BigNumber(proposal.totalVoters)
      .div(quorumNeeded)
      .multipliedBy(100)
      .decimalPlaces(0)
      .toNumber(),
    100
  )

  return (
    <div className="pt-4 mt-4 space-y-5">
      <div className="flex flex-row items-center space-x-2">
        <p className="text-gray-400 text-sm font-light">Quorum</p>
        <Popover
          from={(ref, toggle, _visible, show) => (
            <div
              ref={ref}
              onMouseOver={() => show()}
              onMouseOut={() => toggle()}
              className="leading-none"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="text-gray-400" />
            </div>
          )}
        >
          <div className="p-4 w-80">
            <p className="text-sm text-gray-900">
              For a proposal to pass, it needs to meet quorum. This means that a
              minimum number of {quorumNeeded} members with governance tokens
              that need to vote in a proposal for it to be executable.
            </p>
          </div>
        </Popover>
      </div>

      <div className="bg-gray-100 w-full h-4 rounded-full">
        <div
          className="bg-viz-quorumRed w-1/2 h-4 rounded-full"
          style={{ width: `${quorumPercent}%` }}
        />
      </div>

      <div className="flex flex-row items-center space-x-2">
        <div className="w-5 h-5 bg-viz-quorumRed rounded-md" />

        <p className="text-sm font-light text-gray-700">
          <span className="font-bold">
            {formatNumber(proposal.totalVoters)}
            {` of `}
            {formatNumber(quorumNeeded)}
          </span>
          {' voters'}
        </p>
      </div>

      {proposal.isQuorumReached && (
        <div className="flex flex-row items-center space-x-1">
          <FontAwesomeIcon icon={faCheckCircle} className="text-viz-green" />
          <p className="text-viz-green text-sm">Quorum has been reached</p>
        </div>
      )}
    </div>
  )
}

export default QuorumViz
