import cls from 'classnames'
import {
  CollectiveProposal,
  CollectiveProposalExecutionStatus,
  CollectiveProposalStatus,
  CollectiveProposalType
} from 'graphql/generated'
import { useMemo } from 'react'

type TProposal = Pick<
  CollectiveProposal,
  'status' | 'isBatch' | 'execution' | 'type'
>
interface IProps {
  proposal: TProposal
}

const rootClasses =
  'py-[3px] px-5 text-xs font-medium rounded-xl text-center inline-flex align-middle'
const grayClasses = 'border border-gray-500 text-gray-500'
const redClasses = 'border border-viz-quorumRed text-viz-quorumRed'
const blueClasses = 'border border-blue text-blue'
const greenClasses = 'border border-viz-green text-viz-green'
const orangeClasses = 'border border-viz-orange text-viz-orange'

function ProposalStatusBadge({ proposal }: IProps) {
  const executionStatus = proposal.execution?.status
  const label = useMemo(() => {
    switch (proposal.status) {
      case CollectiveProposalStatus.PENDING:
        return 'Pending'
      case CollectiveProposalStatus.ACTIVE:
        return 'Active'
      case CollectiveProposalStatus.CANCELED:
        return 'Canceled'
      case CollectiveProposalStatus.DEFEATED:
        return 'Defeated'
      case CollectiveProposalStatus.SUCCEEDED:
        return proposal.type === CollectiveProposalType.SURVEY
          ? 'Poll Ended'
          : 'Passed'
      case CollectiveProposalStatus.EXECUTED:
        switch (executionStatus) {
          case CollectiveProposalExecutionStatus.SUCCEEDED:
            return 'Executed'
          case CollectiveProposalExecutionStatus.FAILED:
            return 'Execution Failed'
          case CollectiveProposalExecutionStatus.PENDING:
          default:
            return 'Executing'
        }
      case CollectiveProposalStatus.EXPIRED:
        return 'Expired'
      default:
        return '(Unknown)'
    }
  }, [proposal.status, proposal.type, executionStatus])

  if (
    proposal.status !== CollectiveProposalStatus.EXECUTED &&
    proposal.isBatch
  ) {
    return <div className={cls(rootClasses, blueClasses)}>Batch</div>
  }

  return (
    <div
      className={cls(
        rootClasses,
        proposal.status === CollectiveProposalStatus.PENDING && grayClasses,
        proposal.status === CollectiveProposalStatus.ACTIVE && blueClasses,
        proposal.status === CollectiveProposalStatus.CANCELED && grayClasses,
        proposal.status === CollectiveProposalStatus.SUCCEEDED && orangeClasses,
        proposal.status === CollectiveProposalStatus.EXECUTED &&
          executionStatus !== CollectiveProposalExecutionStatus.FAILED &&
          greenClasses,
        proposal.status === CollectiveProposalStatus.EXECUTED &&
          executionStatus === CollectiveProposalExecutionStatus.FAILED &&
          redClasses,
        proposal.status === CollectiveProposalStatus.DEFEATED && redClasses,
        proposal.status === CollectiveProposalStatus.EXPIRED && grayClasses
      )}
    >
      {label}
    </div>
  )
}

export default ProposalStatusBadge
