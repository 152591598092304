import {
  faCheck,
  faDoNotEnter,
  faTimes
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CollectiveProposalQueryResult, VoteType } from 'graphql/generated'
import { Typography } from 'components/common'
import { twMerge } from 'tailwind-merge'

type TProposal = Pick<
  NonNullable<CollectiveProposalQueryResult['collectiveProposal']>,
  'id' | 'title' | 'myVote' | 'canVote'
>

const optionContainerClasses =
  'p-3 rounded-md cursor-pointer w-[120px] flex flex-col justify-center items-center'

const optionContainerDisallowedClasses =
  'text-gray-400 bg-gray-100 cursor-not-allowed'
const iconClasses = 'text-2xl mb-1 text-gray-700'
const textClasses = 'font-light text-gray-700'

export function VoteSwitch({
  value,
  onChange,
  proposal
}: {
  value: Maybe<VoteType>
  onChange: CallbackWithParam<VoteType>
  proposal: TProposal
}) {
  const hasAlreadyVotedFor = proposal.myVote === VoteType.FOR
  const hasAlreadyVotedAgainst = proposal.myVote === VoteType.AGAINST
  const hasAlreadyVotedAbstain = proposal.myVote === VoteType.ABSTAIN

  const currentVoteChoiceIsFor = value === VoteType.FOR
  const currentVoteChoiceIsAgainst = value === VoteType.AGAINST
  const currentVoteChoiceIsAbstain = value === VoteType.ABSTAIN

  const forAdditionalClass =
    (hasAlreadyVotedFor && optionContainerDisallowedClasses) ||
    (currentVoteChoiceIsFor && 'bg-viz-yesActive')

  const againstAdditionalClass =
    (hasAlreadyVotedAgainst && optionContainerDisallowedClasses) ||
    (currentVoteChoiceIsAgainst && 'bg-viz-yesActive')

  const abstainAdditionalClass =
    (hasAlreadyVotedAbstain && optionContainerDisallowedClasses) ||
    (currentVoteChoiceIsAbstain && 'bg-viz-yesActive')

  return (
    <div className={'flex flex-row justify-center items-center'}>
      <div className={'bg-gray-100 p-2.5 rounded-md flex flex-row '}>
        <div
          onClick={() => onChange(VoteType.FOR)}
          className={twMerge(optionContainerClasses, forAdditionalClass)}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className={twMerge(
              iconClasses,
              currentVoteChoiceIsFor && 'text-white'
            )}
          />
          <Typography
            className={twMerge(
              textClasses,
              currentVoteChoiceIsFor && 'text-white'
            )}
          >
            Vote For
          </Typography>
        </div>

        <div
          onClick={() => onChange(VoteType.AGAINST)}
          className={twMerge(optionContainerClasses, againstAdditionalClass)}
        >
          <FontAwesomeIcon
            icon={faTimes}
            className={twMerge(
              iconClasses,
              currentVoteChoiceIsAgainst && 'text-white'
            )}
          />
          <Typography
            className={twMerge(
              textClasses,
              currentVoteChoiceIsAgainst && 'text-white'
            )}
          >
            Vote Against
          </Typography>
        </div>

        <div
          onClick={() => onChange(VoteType.ABSTAIN)}
          className={twMerge(optionContainerClasses, abstainAdditionalClass)}
        >
          <FontAwesomeIcon
            icon={faDoNotEnter}
            className={twMerge(
              iconClasses,
              currentVoteChoiceIsAbstain && 'text-white'
            )}
          />
          <Typography
            className={twMerge(
              textClasses,
              currentVoteChoiceIsAbstain && 'text-white'
            )}
          >
            Abstain
          </Typography>
        </div>
      </div>
    </div>
  )
}
