import { AnswerInput, CollectiveProposalQueryResult } from 'graphql/generated'

export const checkHasEmptyRequiredQuestions = (
  answers: Array<AnswerInput>,
  proposal: Pick<
    NonNullable<CollectiveProposalQueryResult['collectiveProposal']>,
    'survey'
  >
) => {
  return !!proposal.survey?.questions.find(
    (q, i) => q.options.required && answers[i] && !answers[i].choices?.length
  )
}
