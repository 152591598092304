import { IMemberTypes } from 'components/Collectives/Demo/common'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { VoteType } from 'graphql/generated'
import { useCallback } from 'react'
import { useUpdateProposalDataVotes } from './useUpdateProposalsDataValue'

export const useDemoVote = () => {
  const { collectiveDemo, currentUser, updateDemoCache } =
    useCollectiveDemoContext()

  const updateProposalDataVotes = useUpdateProposalDataVotes()

  const demoVote = useCallback(
    (id: string, forAgainst: VoteType, user: IMemberTypes = currentUser) => {
      if (!collectiveDemo) {
        return
      }

      const newCollectiveDemo = { ...collectiveDemo }

      const userMembership = user.membership

      const proposalFound = newCollectiveDemo.proposals.edges.find(
        proposal => proposal.node.id === id
      )

      if (!proposalFound) {
        return
      }

      proposalFound.node.myVote = forAgainst
      proposalFound.node.canVote = user === currentUser ? false : true

      proposalFound.node.totalVotes = (
        Number(proposalFound.node.totalVotes) +
        Number(userMembership.totalTokens)
      ).toString()

      proposalFound.node.votesAgainst =
        forAgainst === VoteType.AGAINST
          ? (
              Number(proposalFound.node.votesAgainst) +
              Number(userMembership.totalTokens)
            ).toString()
          : proposalFound.node.votesAgainst

      proposalFound.node.votesFor =
        forAgainst === VoteType.FOR
          ? (
              Number(proposalFound.node.votesFor) +
              Number(userMembership.totalTokens)
            ).toString()
          : proposalFound.node.votesFor

      proposalFound.node.totalVoters = (
        Number(proposalFound.node.totalVoters) + 1
      ).toString()

      updateProposalDataVotes(id, forAgainst, user)

      updateDemoCache(newCollectiveDemo)

      user.votes[id] = forAgainst
    },
    [collectiveDemo, currentUser, updateDemoCache, updateProposalDataVotes]
  )

  return demoVote
}
