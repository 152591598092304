import Thumbnail from 'components/Thumbnail'
import { Typography, Checkbox } from 'components/common'
import { CollectiveMemberFieldsFragment } from 'graphql/generated'
import { formatToken } from 'lib/collectives/helpers'

interface IProposalDelegatorItem {
  member: CollectiveMemberFieldsFragment
  isChecked: boolean
  onChange: CallbackWithParam<boolean>
  formatWeight: boolean
}

export function ProposalDelegatorItem({
  onChange,
  isChecked,
  member,
  formatWeight
}: IProposalDelegatorItem) {
  return (
    <div
      className="border flex cursor-pointer py-[15px] px-[10px] rounded-xl mb-5 bg-white border-white hover:border-viz-lightGray"
      onClick={() => onChange(!isChecked)}
    >
      <div className="flex justify-center w-[30px]">
        <Checkbox checked={isChecked} />
      </div>

      <div className="pr-5 flex justify-center w-[70px]">
        <Thumbnail
          src={member.user.platformUser?.thumb}
          className="h-12 w-12 rounded-full overflow-hidden"
        />
      </div>

      <div className="flex flex-1 flex-col">
        <div className="flex flex-col justify-center">
          <Typography color="darkGray" size="sm">
            {member.user.platformUser?.displayName}
          </Typography>

          <Typography fontWeight="light" color="gray" size="sm">
            {member.user.address}
          </Typography>

          <Typography color="darkGray" size="sm" className="pt-[5px]">
            {`Voting Power: ${
              formatWeight
                ? formatToken(member.totalTokens)
                : member.totalTokens
            } Votes`}
          </Typography>
        </div>
      </div>
    </div>
  )
}
