import Thumbnail from 'components/Thumbnail'
import { CollectiveMemberFieldsFragment } from 'graphql/generated'
import { Typography } from 'components/common'
import EtherscanLink from '../Common/EtherscanLink'

export const getEffectiveName = (member: CollectiveMemberFieldsFragment) =>
  member.user.platformUser
    ? member.user.platformUser.displayName
    : member.user.address

interface IProps {
  member: CollectiveMemberFieldsFragment
  errorMessage: string
}

function FailedVoteListItem({ member, errorMessage }: IProps) {
  const effectiveName = getEffectiveName(member)

  return (
    <div className="flex items-center">
      <div className="p-2 text-center whitespace-nowrap flex-1">
        <div className="flex items-center space-x-2 text-left">
          <Thumbnail
            src={member.user.platformUser?.thumb}
            className="h-10 w-10 rounded-full overflow-hidden"
          />

          <Typography fontWeight="light" size="sm">
            {effectiveName}
            <EtherscanLink
              shortAddress
              type={'address'}
              address={member.user.address}
            />
          </Typography>
        </div>
      </div>

      <div className="p-2 whitespace-nowrap text-left flex-1">
        <p className="text-gray-500 uppercase font-medium text-xs">
          Failure Reason:
        </p>
        <p className="text-gray-700 font-light">{errorMessage}</p>
      </div>
    </div>
  )
}

export default FailedVoteListItem
