import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Thumbnail from 'components/Thumbnail'
import Popover from 'components/common/Popover'
import CollectiveUserInfo, {
  IProps as ICollectiveUserInfoProps
} from './CollectiveUserInfo'
import Link from 'next/link'
import { faChevronCircleRight } from '@fortawesome/pro-duotone-svg-icons'
import { twMerge } from 'tailwind-merge'
import { calculateShortAddress } from './EtherscanLink'
import { EthNetwork } from 'graphql/generated'
import { getEtherscanUrl } from 'lib/collectives/helpers'
import { useEventEmitterContext } from 'context/EventEmitterContext'

interface IProps extends ICollectiveUserInfoProps {
  textStyle?: string
  noAvatar?: boolean
  asLink?: boolean
  style?: 'chevron' | 'dotted'
}

const closeOnEvent = 'COLLECTIVE_USER_POPOVER'

function CollectiveUserPopover({
  user,
  network,
  noAvatar,
  textStyle,
  asLink = true,
  style = 'chevron'
}: IProps) {
  const showAvatar = !noAvatar && style !== 'dotted'
  const isChevron = style === 'chevron'
  const content = (
    <>
      {showAvatar && (
        <Thumbnail
          src={user.platformUser?.thumb}
          className="h-6 w-6 rounded-full overflow-hidden"
        />
      )}

      <span
        className={twMerge(
          'text-sm text-gray-600',
          style === 'dotted' && 'border-b border-gray-400 border-dashed',
          !isChevron && 'hover:text-gray-900',
          textStyle
        )}
      >
        {user.platformUser
          ? user.platformUser.displayName
          : calculateShortAddress(user.address)}
      </span>

      {style === 'chevron' && (
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          className="opacity-20 group-hover:opacity-100 relative left-0 group-hover:left-1 transition-all"
        />
      )}
    </>
  )

  const { emitter } = useEventEmitterContext()
  const hideOthers = () => {
    emitter.emit(closeOnEvent)
  }

  return (
    <Popover
      closeOnEvent={closeOnEvent}
      placement="bottom-start"
      useFade
      from={(ref, _toggle, _visible, show) => (
        <div
          ref={ref}
          className={twMerge('inline-flex group', isChevron && 'pr-1')}
        >
          {asLink ? (
            <Link
              href={
                user.platformUser
                  ? `/profiles/${user.platformUser.id}`
                  : getEtherscanUrl(
                      network ?? EthNetwork.GOERLI,
                      'address',
                      user.address
                    )
              }
              onMouseOver={() => {
                hideOthers()
                show()
              }}
              onClick={e => {
                e.stopPropagation()
              }}
              className={twMerge(
                'flex flex-row items-center space-x-1',
                isChevron && 'relative'
              )}
            >
              {content}
            </Link>
          ) : (
            <div
              onMouseOver={() => {
                hideOthers()
                show()
              }}
              // onMouseOut={() => toggle()}
              className={twMerge(
                'flex flex-row items-center space-x-1',
                isChevron && 'relative'
              )}
            >
              {content}
            </div>
          )}
        </div>
      )}
    >
      {({ toggle }) => (
        <div className="relative py-3 px-4" onMouseLeave={() => toggle()}>
          <CollectiveUserInfo user={user} network={network} />
        </div>
      )}
    </Popover>
  )
}

export default CollectiveUserPopover
