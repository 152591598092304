import {
  CastVoteWithDelegationsMutationResult,
  VoteType
} from 'graphql/generated'
import SuccessfulVoteListItem from './SuccessfulVoteListItem'
import FailedVoteListItem from './FailedVoteListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle
} from '@fortawesome/pro-duotone-svg-icons'

interface IProps {
  results: CastVoteWithDelegationsMutationResult
  voteType: VoteType
  formatWeight: boolean
}

function ProposalVoteResults({ results, voteType, formatWeight }: IProps) {
  const hasFailures =
    results.castVoteWithDelegations &&
    results.castVoteWithDelegations.delegationFailures.length > 0

  if (!results.castVoteWithDelegations) {
    return (
      <div>
        <p className="text-gray-700">
          Failed to cast votes. Please try again later.
        </p>
      </div>
    )
  }

  return (
    <div>
      {hasFailures ? (
        <div className="bg-gray-100 rounded-lg p-4 mb-5">
          <p className="text-center text-red-600 pb-2 font-light">
            <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
            {'Failed to cast some of your votes:'}
          </p>

          {results.castVoteWithDelegations.delegationFailures.map(
            ({ collectiveMember, message }) => (
              <FailedVoteListItem
                key={collectiveMember.id}
                member={collectiveMember}
                errorMessage={message}
              />
            )
          )}
        </div>
      ) : (
        <p className="text-center text-green-600 pb-2 font-light">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
          {results.castVoteWithDelegations.votes.length > 1
            ? 'All your votes have been cast successfully'
            : 'Your vote has been cast successfully'}
        </p>
      )}

      <table className="w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-gray-600 uppercase font-normal text-left">
              Voter
            </th>
            <th className="px-4 py-2 text-gray-600 uppercase font-normal text-left">
              Vote
            </th>
            <th className="px-4 py-2 text-gray-600 uppercase font-normal text-left">
              Weight
            </th>
          </tr>
        </thead>

        <tbody>
          {results.castVoteWithDelegations?.votes.map(({ member }) => (
            <SuccessfulVoteListItem
              key={member.id}
              member={member}
              voteType={voteType}
              formatWeight={formatWeight}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProposalVoteResults
