import { CollectiveProposalQuery, ViewerQuery } from 'graphql/documents'
import {
  AnswerInput,
  CollectiveProposalQueryResult,
  CollectiveProposalType,
  DelegationsPayload,
  useCastVoteWithDelegationsMutation,
  VoteType
} from 'graphql/generated'
import { useQueryErrorHandler } from 'hooks'
import { parseWeb3ErrorMessage, TTxUiState } from 'lib/collectives/helpers'
import { useState } from 'react'
import useToasts from 'hooks/useToasts'
import { useWeb3 } from 'context/Web3Context'
import { checkHasEmptyRequiredQuestions } from 'components/Collectives/CollectiveProposals/util'
import Bugsnag from '@bugsnag/js'

interface IProps {
  proposal: Pick<
    NonNullable<CollectiveProposalQueryResult['collectiveProposal']>,
    'id' | 'myVote' | 'canVote' | 'type' | 'survey' | 'requireVotingSignature'
  > & {
    collective: Pick<
      NonNullable<
        CollectiveProposalQueryResult['collectiveProposal']
      >['collective'],
      'id'
    >
  } & {
    commands?: NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['commands']
  }
}

type TPayload = {
  timestamp: number
  collective: string
  type: string
  payload:
    | {
        proposal: string
        vote: VoteType
      }
    | {
        proposal: string
        surveyAnswers: AnswerInput[]
      }
  voteForDelegateIds: string[]
}

export function useCastVoteWithDelegations({ proposal }: IProps) {
  const { addToast } = useToasts()
  const { signer } = useWeb3()

  const isSurveyType = proposal.type === CollectiveProposalType.SURVEY

  const onError = useQueryErrorHandler(`Failed to cast vote with delegations`)
  const [registerCastVote, result] = useCastVoteWithDelegationsMutation({
    onError,
    refetchQueries: [
      {
        query: ViewerQuery
      },
      {
        query: CollectiveProposalQuery,
        variables: {
          proposalId: proposal.id
        }
      }
    ]
  })
  const [state, setState] = useState<Maybe<TTxUiState>>(null)

  const castVote = async ({
    vote,
    surveyAnswers,
    delegationsPayload
  }: {
    vote: Maybe<VoteType>
    surveyAnswers?: Maybe<AnswerInput[]>
    delegationsPayload: DelegationsPayload[]
  }) => {
    try {
      if (vote === null || vote === undefined) {
        return
      } else if (!proposal.canVote) {
        return
      }

      if (isSurveyType) {
        if (
          !surveyAnswers ||
          !proposal.survey ||
          checkHasEmptyRequiredQuestions(surveyAnswers, proposal)
        ) {
          return
        }
      }

      setState(TTxUiState.WAITING_FOR_PROVIDER)
      const payload: TPayload = {
        timestamp: Date.now(),
        collective: proposal.collective?.id,
        type: 'vote',
        payload: !isSurveyType
          ? {
              proposal: proposal.id,
              vote: vote
            }
          : { proposal: proposal.id, surveyAnswers: surveyAnswers! },
        voteForDelegateIds: delegationsPayload.map(
          x => x.delegatorCollectiveUserId
        )
      }

      const signature = proposal.requireVotingSignature
        ? await signer?.signMessage(JSON.stringify(payload))
        : undefined

      if (proposal.requireVotingSignature && !signature) {
        throw new Error(`Invalid signature`)
      }

      setState(TTxUiState.WAITING_FOR_NETWORK)
      await registerCastVote({
        variables: {
          vote: {
            proposalId: proposal.id,
            type: vote,
            signature,
            useDelegation: true,
            delegationsPayload,
            payload
          }
        }
      })

      if (proposal.type === CollectiveProposalType.COMMANDS) {
        window.analytics.trackAll(`Proposal - Vote - Complete`, {
          proposalId: proposal.id,
          voteValue: vote
        })
      }

      window.analytics.trackAll(
        isSurveyType ? `Survey Answered` : `Proposal Vote Cast`,
        {
          collectiveId: proposal.collective.id,
          proposalId: proposal.id,
          commands: proposal.commands
            ? proposal.commands.map(x => x.__typename)
            : [],
          questions: proposal.survey?.totalQuestions,
          answeresGiven: surveyAnswers
            ? surveyAnswers.filter(an => !!an.choices?.length).length
            : 0
        }
      )

      setState(TTxUiState.TX_SUCCESS)
    } catch (err) {
      Bugsnag.notify(err)
      addToast(parseWeb3ErrorMessage(err), {
        appearance: 'warning'
      })
      if (proposal.type === CollectiveProposalType.COMMANDS) {
        window.analytics.trackAll(`Proposal - Vote - Error`, {
          proposalId: proposal.id,
          voteValue: vote
        })
      }
      setState(null)
    }
  }

  return { state, castVote, result }
}
