import Popover from 'components/common/Popover'
import cls from 'classnames'
import { BigNumber } from 'lib/BigInt'
import {
  Collective,
  CollectiveProposal,
  CollectiveProposalStatus
} from 'graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { formatToken } from 'lib/collectives/helpers'

export type TCollective = {
  tokens: Pick<Collective['tokens'][0], 'totalVotingSupply' | 'decimals'>[]
}
export type TProposal = Pick<
  CollectiveProposal,
  | 'votesFor'
  | 'votesAgainst'
  | 'votesAbstain'
  | 'totalVotes'
  | 'isQuorumReached'
  | 'status'
>

interface IProps {
  collective: TCollective
  proposal: TProposal
  variant?: 'narrow' | 'normal'
}

function VotingViz({ collective, proposal, variant }: IProps) {
  const hasVotes = new BigNumber(proposal.totalVotes).gt(0)
  const hasAbstains = new BigNumber(proposal.votesAbstain).gt(0)
  const yesPercent = hasVotes
    ? new BigNumber(proposal.votesFor)
        .div(proposal.totalVotes)
        .multipliedBy(100)
        .decimalPlaces(1)
        .toNumber()
    : 0
  const noPercent = hasVotes
    ? new BigNumber(proposal.votesAgainst)
        .div(proposal.totalVotes)
        .multipliedBy(100)
        .decimalPlaces(1)
        .toNumber()
    : 0
  const abstainPercent = hasVotes
    ? new BigNumber(proposal.votesAbstain)
        .div(proposal.totalVotes)
        .multipliedBy(100)
        .decimalPlaces(1)
        .toNumber()
    : 0

  const isActive = proposal.status === CollectiveProposalStatus.ACTIVE
  const wasProposalSuccessful = [
    CollectiveProposalStatus.SUCCEEDED,
    CollectiveProposalStatus.EXECUTED
  ].includes(proposal.status)
  const isProposalPassing =
    (isActive &&
      proposal.isQuorumReached &&
      new BigNumber(proposal.votesFor).isGreaterThan(proposal.votesAgainst)) ||
    wasProposalSuccessful

  const useInactiveColors = !proposal.isQuorumReached && !wasProposalSuccessful

  const totalVotesNeededToEndEarly = new BigNumber(
    collective.tokens[0].totalVotingSupply
  )
    .div(2)
    .decimalPlaces(0)
    .toNumber()
  const remainingVotesNeededToEndEarly = new BigNumber(
    totalVotesNeededToEndEarly
  )
    .minus(proposal.votesFor)
    .decimalPlaces(0)
    .toNumber()
  const endedEarly = new BigNumber(remainingVotesNeededToEndEarly).lt(0)
  const barWidthMultiplier = endedEarly ? 0.99 : 0.87

  return (
    <div className={variant === 'normal' ? 'space-y-5' : 'space-y-3'}>
      <p className="text-gray-400 text-sm font-light">Total Votes</p>

      <div className="relative bg-gray-100 w-full h-4 rounded-full flex flex-row space-x-0.5">
        <div
          className="bg-viz-no w-1/2 h-4 rounded-full"
          // leave 10% for gray background to give the illusion that there's more votes pending
          style={{
            width: `${new BigNumber(noPercent).multipliedBy(
              barWidthMultiplier
            )}%`
          }}
        />

        {hasAbstains && (
          <div
            className="bg-viz-abstained w-1/2 h-4 rounded-full"
            // leave 10% for gray background to give the illusion that there's more votes pending
            style={{
              width: `${new BigNumber(abstainPercent).multipliedBy(
                barWidthMultiplier
              )}%`
            }}
          />
        )}

        <div
          className={cls(
            useInactiveColors ? 'bg-viz-yesInactive' : 'bg-viz-yesActive',
            'w-1/2 h-4 rounded-full'
          )}
          // leave 10% for gray background to give the illusion that there's more votes pending
          style={{
            width: `${new BigNumber(yesPercent).multipliedBy(
              barWidthMultiplier
            )}%`
          }}
        />

        {proposal.isQuorumReached && (
          <Popover
            placement="top-end"
            from={(ref, toggle, _visible, show) => (
              <div
                ref={ref}
                onMouseOver={() => show()}
                onMouseOut={() => toggle()}
                className="absolute bg-gray-900 border-white border-3 h-4 w-4 rounded-full cursor-help"
                style={{ left: '90%' }}
              >
                <p className="absolute -top-6 -left-7 text-xxs font-light whitespace-nowrap text-gray-900">
                  Ending early
                </p>
              </div>
            )}
          >
            <div className="py-2 px-4 w-80">
              {!endedEarly && (
                <p className="text-sm font-light text-gray-900 pt-2 pb-3 text-center">
                  {formatToken(remainingVotesNeededToEndEarly, {
                    decimals: collective.tokens[0].decimals,
                    roundToDecimals: 2
                  })}
                  {' more votes for voting to end early'}
                </p>
              )}
              {endedEarly && (
                <p className="text-sm font-light text-gray-900 pt-2 pb-3 text-center">
                  {'This proposal garnished enough votes to end early'}
                </p>
              )}

              <div className="bg-gray-100 p-2 rounded-lg">
                <p className="text-xs pb-1 text-gray-700">
                  Why and when do proposals end early?
                </p>
                <p className="text-xs font-light text-gray-700">
                  Once proposals meet quorum requirements, and have garnished
                  enough votes so that they can no longer be out-voted, they can
                  automatically end early.
                </p>
              </div>
            </div>
          </Popover>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center space-x-2">
          <div className="w-5 h-5 bg-viz-no rounded-md" />

          <p className="text-sm font-light text-gray-700">
            <span className="font-bold">
              {formatToken(proposal.votesAgainst, {
                decimals: collective.tokens[0].decimals,
                roundToDecimals: 2
              })}
              {` No`}
            </span>
            {` - ${noPercent}%`}
          </p>
        </div>

        {hasAbstains && (
          <div className="flex flex-row items-center space-x-2">
            <div className="w-5 h-5 bg-viz-abstained rounded-md" />

            <p className="text-sm font-light text-gray-700">
              <span className="font-bold">
                {formatToken(proposal.votesAbstain, {
                  decimals: collective.tokens[0].decimals,
                  roundToDecimals: 2
                })}
                {` Abstain`}
              </span>
              {` - ${abstainPercent}%`}
            </p>
          </div>
        )}

        <div className="flex flex-row items-center space-x-2">
          <div
            className={cls(
              useInactiveColors ? 'bg-viz-yesInactive' : 'bg-viz-yesActive',
              'w-5 h-5 rounded-md'
            )}
          />

          <p className="text-sm font-light text-gray-700">
            <span className="font-bold">
              {formatToken(proposal.votesFor, {
                decimals: collective.tokens[0].decimals,
                roundToDecimals: 2
              })}
              {` Yes`}
            </span>
            {` - ${yesPercent}%`}
          </p>
        </div>
      </div>

      {isProposalPassing && (
        <div className="flex flex-row items-center space-x-1">
          <FontAwesomeIcon icon={faCheckCircle} className="text-viz-green" />
          <p className="text-viz-green text-sm">
            {'Proposal '}
            {wasProposalSuccessful ? `has passed` : `is passing`}
          </p>
        </div>
      )}
    </div>
  )
}

export default VotingViz
