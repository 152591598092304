import { useCollectiveContext } from 'context/CollectiveContext'
import BaseModal from 'components/modals/BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUsers } from '@fortawesome/pro-regular-svg-icons'
import Button from 'components/Button'
import { CollectiveQueryResult } from 'graphql/generated'

type TCollective = Pick<
  NonNullable<CollectiveQueryResult['collective']>,
  'membership'
>

type TRole = 'approved' | 'privileged'

interface IProps {
  collective: TCollective
  requiredRole?: TRole
  children: React.ReactNode
  zIndex?: number
  onClose?: Callback
}

function MembershipRequiredModal({
  collective,
  requiredRole,
  children,
  zIndex,
  onClose
}: IProps) {
  const { demoMode } = useCollectiveContext()

  const hasAuthorizedRole =
    !requiredRole ||
    (requiredRole === 'privileged' && collective.membership?.privileged) ||
    (requiredRole === 'approved' && collective.membership?.approved)

  if (hasAuthorizedRole || demoMode) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return (
    <BaseModal zIndex={zIndex} onClose={onClose}>
      <div className="relative flex flex-col justify-center items-center px-10 pt-8 pb-4 space-y-2">
        <div
          className="absolute top-2 right-2 flex justify-center items-center text-blue-400 text-xl hover:bg-blue-100 active:bg-blue-200 w-7 h-7 rounded-full cursor-pointer"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <FontAwesomeIcon icon={faUsers} className="text-blue-400" size={'3x'} />

        <div className="py-10">
          <p className="text-center font-medium text-xl pb-2">
            {`Your DAO Membership is Pending`}
          </p>

          <p className="text-center font-light text-sm">
            {`You'll need to be approved before you can do this action.`}
          </p>
        </div>

        <Button
          label={'Dismiss'}
          onClick={onClose}
          size={'md'}
          color={'white'}
        />
      </div>
    </BaseModal>
  )
}

export default MembershipRequiredModal
