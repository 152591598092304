import Thumbnail from 'components/Thumbnail'
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { faDoNotEnter } from '@fortawesome/pro-solid-svg-icons'
import { CollectiveMemberFieldsFragment, VoteType } from 'graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from 'components/common'
import { formatToken } from 'lib/collectives/helpers'
import { useMemo } from 'react'
import EtherscanLink from '../Common/EtherscanLink'

export const getEffectiveName = (member: CollectiveMemberFieldsFragment) =>
  member.user.platformUser
    ? member.user.platformUser.displayName
    : member.user.address

interface IProps {
  member: CollectiveMemberFieldsFragment
  voteType: VoteType
  formatWeight: boolean
}

function SuccessfulVoteListItem({ member, voteType, formatWeight }: IProps) {
  const effectiveName = getEffectiveName(member)
  const voteIcon = useMemo(
    () =>
      voteType === VoteType.FOR
        ? faCheck
        : voteType === VoteType.AGAINST
        ? faTimes
        : faDoNotEnter,
    [voteType]
  )

  return (
    <tr>
      <td className="p-2 text-center whitespace-nowrap">
        <div className="flex items-center space-x-2 text-left">
          <Thumbnail
            src={member.user.platformUser?.thumb}
            className="h-10 w-10 rounded-full overflow-hidden"
          />

          <Typography fontWeight="light" size="sm">
            {effectiveName}
            <EtherscanLink
              shortAddress
              type={'address'}
              address={member.user.address}
            />
          </Typography>
        </div>
      </td>

      <td className="p-2 text-center whitespace-nowrap">
        <div className="flex items-center space-x-1">
          <FontAwesomeIcon
            icon={voteIcon}
            size={'1x'}
            className={'text-gray-900'}
          />
          <Typography fontWeight="light">{voteType}</Typography>
        </div>
      </td>

      <td className="p-2 text-center whitespace-nowrap">
        <Typography fontWeight="light">
          {formatWeight ? formatToken(member.totalTokens) : member.totalTokens}
        </Typography>
      </td>
    </tr>
  )
}

export default SuccessfulVoteListItem
