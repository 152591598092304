import { BigNumber } from 'lib/BigInt'
import { useCollectiveContext } from 'context/CollectiveContext'
import { useCallback, useMemo, useState } from 'react'
import {
  CollectiveProposalQueryResult,
  VoteType,
  useCollectiveProposalDelegationsQuery,
  useCollectiveQuery,
  CollectiveMemberFieldsFragment,
  CollectiveTokenType,
  CollectiveQueryResult,
  AnswerInput,
  CollectiveProposalType
} from 'graphql/generated'
import { TTxUiState } from 'lib/collectives/helpers'
import Web3ActionModal from 'components/Collectives/modals/Web3ActionModal'
import MembershipRequiredModal from './MembershipRequiredModal'
import { ProposalDelegationsList } from '../Common/ProposalDelegationsList'
import { VoteSwitch } from '../Common/VoteSwitch'
import { useCastVoteWithDelegations } from 'hooks/collectives/useCastVoteWithDelegations'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { useDemoVote } from 'hooks/collectives/demo/useDemoVote'
import Spinner from 'components/Spinner'
import { noop } from 'lodash'
import ProposalVoteResults from '../ProposalVoteResults/ProposalVoteResults'
import Button from 'components/Button'
import { useWalletCheck } from 'hooks/collectives/useWalletCheck'
import WalletCheckInfo from '../Common/WalletCheckInfo'

type TProposal = Pick<
  NonNullable<CollectiveProposalQueryResult['collectiveProposal']>,
  | 'id'
  | 'title'
  | 'status'
  | 'isBatch'
  | 'isPartOfBatch'
  | 'canVote'
  | 'myVote'
  | 'type'
  | 'survey'
  | 'requireVotingSignature'
> & {
  collective: Pick<
    NonNullable<
      CollectiveProposalQueryResult['collectiveProposal']
    >['collective'],
    'id'
  >
} & {
  commands?: NonNullable<
    CollectiveProposalQueryResult['collectiveProposal']
  >['commands']
}

export function CollectiveCastVoteModal({
  proposal,
  zIndex,
  onVoteSuccess,
  onClose,
  onSuccessDismiss,
  surveyAnswers
}: {
  proposal: TProposal
  zIndex?: number
  onVoteSuccess?: CallbackWithParam<VoteType>
  onSuccessDismiss?: Callback
  onClose?: Callback
  surveyAnswers?: AnswerInput[]
}) {
  const { demoMode } = useCollectiveContext()
  const { currentUser } = useCollectiveDemoContext()
  const demoVote = useDemoVote()

  const isCommandsType = proposal.type === CollectiveProposalType.COMMANDS

  const { data: collectiveResp } = useCollectiveQuery({
    variables: {
      collectiveId: proposal.collective.id,
      proposalConnection: {
        first: 0
      }
    },
    skip: demoMode
  })
  const collective = demoMode
    ? (proposal.collective as NonNullable<CollectiveQueryResult>['collective'])
    : collectiveResp?.collective

  const { data } = useCollectiveProposalDelegationsQuery({
    variables: {
      proposalId: proposal.id
    },
    skip: demoMode,
    fetchPolicy: 'cache-and-network'
  })
  const eligibleDelegations = useMemo(
    () => data?.collectiveProposal?.eligibleDelegations || [],
    [data]
  )

  const { castVote, state, result } = useCastVoteWithDelegations({
    proposal
  })
  const [selectedDelegateIds, setSelectedDelegateIds] = useState<UUID[]>([])

  const [vote, setVote] = useState<Maybe<VoteType>>(
    isCommandsType
      ? proposal.myVote === 'FOR'
        ? VoteType.AGAINST
        : VoteType.FOR
      : VoteType.FOR
  )

  const changeVote = (newVote: VoteType) => {
    if (proposal.myVote !== newVote) {
      setVote(newVote)
    }
  }

  const doCastVote = useCallback(async () => {
    if (demoMode && vote) {
      demoVote(proposal.id, vote, currentUser)
      onClose?.()
      return
    }

    await castVote({
      vote,
      delegationsPayload: selectedDelegateIds.map(v => ({
        delegatorCollectiveUserId: v
      })),
      surveyAnswers
    })

    if (onVoteSuccess && vote) {
      onVoteSuccess(vote)
    }
    return
  }, [
    demoMode,
    vote,
    castVote,
    selectedDelegateIds,
    surveyAnswers,
    onVoteSuccess,
    demoVote,
    proposal.id,
    currentUser,
    onClose
  ])

  const totalVotingPower = useMemo(
    () =>
      calculateVotingPower(
        eligibleDelegations,
        selectedDelegateIds,
        collective?.membership?.totalTokens,
        collective?.governanceType === CollectiveTokenType.OWNED
      ),
    [
      eligibleDelegations,
      selectedDelegateIds,
      collective?.membership?.totalTokens,
      collective?.governanceType
    ]
  )

  const votesCountText = useMemo(
    () =>
      isCommandsType
        ? vote === VoteType.AGAINST
          ? 'voting in opposition,'
          : vote === VoteType.FOR
          ? 'voting in favor,'
          : 'abstaining from voting,'
        : 'Answering survey',
    [isCommandsType, vote]
  )

  const { showWalletNotice } = useWalletCheck(collective)

  if (!collective) {
    return (
      <Web3ActionModal
        title={`Cast vote \u00b7 ${proposal.title}`}
        titleVariant={'h6'}
        confirmLabel={'Submit Vote'}
        state={undefined}
        onConfirm={noop}
        onClose={onClose}
        disableConfirm={true}
        zIndex={zIndex}
      >
        <div className="flex flex-col justify-center items-center py-10 w-full">
          <Spinner />
        </div>
      </Web3ActionModal>
    )
  } else if (showWalletNotice && !demoMode) {
    return (
      <Web3ActionModal
        title={`Cast vote \u00b7 ${proposal.title}`}
        titleVariant={'h6'}
        confirmLabel={'Submit Vote'}
        state={undefined}
        onConfirm={noop}
        onClose={onClose}
        disableConfirm={true}
        zIndex={zIndex}
        requireWeb3={collective.parameters.requireVotingSignature}
      >
        <WalletCheckInfo
          collective={collective}
          className="bg-red-50 hover:bg-red-100 px-5 py-3 rounded-lg my-3"
        />
      </Web3ActionModal>
    )
  }

  const hasMinimumBalance = collective.membership
    ? new BigNumber(collective.membership.totalTokens).gt(0)
    : false
  const canVote = hasMinimumBalance && proposal.canVote
  const canSubmit =
    canVote && !!vote && (vote !== proposal.myVote || !isCommandsType)
  const isVoteChange = canVote && proposal.myVote

  return (
    <MembershipRequiredModal
      collective={collective}
      requiredRole={'approved'}
      zIndex={zIndex}
      onClose={onClose}
    >
      <Web3ActionModal
        title={
          state === TTxUiState.TX_SUCCESS
            ? 'Voting results'
            : isVoteChange
            ? `Change vote \u00b7 ${proposal.title}`
            : `Cast vote \u00b7 ${proposal.title}`
        }
        titleVariant={'h6'}
        requireWeb3={collective.parameters.requireVotingSignature}
        confirmLabel={isVoteChange ? 'Change Vote' : 'Submit Vote'}
        state={TTxUiState.TX_SUCCESS === state ? undefined : state}
        onConfirm={doCastVote}
        disableConfirm={!canSubmit}
        onClose={onClose}
        labels={{
          [TTxUiState.TX_SUCCESS]: `You've succesfully cast your vote`
        }}
        ignoreDemoMode={true}
        zIndex={zIndex}
        hideButtons={TTxUiState.TX_SUCCESS === state}
      >
        <div className="p-[10px] w-full">
          {!hasMinimumBalance && (
            <div className="flex flex-1 justify-center items-center mb-2">
              <div className="bg-red-200 px-5 py-2 rounded-lg">
                <p className="text-center font-light">{`You don't have enough tokens to vote on this proposal. You must own at least 1 ${collective.tokens[0].symbol} token to vote on this proposal.`}</p>
              </div>
            </div>
          )}

          {canVote && state != TTxUiState.TX_SUCCESS && (
            <div className="max-h-[500px] overflow-auto">
              <ProposalDelegationsList
                selectedValues={selectedDelegateIds}
                onChange={setSelectedDelegateIds}
                memberLists={eligibleDelegations}
                formatWeight={
                  collective.governanceType === CollectiveTokenType.OWNED
                }
              />

              {isCommandsType && (
                <VoteSwitch
                  value={vote}
                  onChange={changeVote}
                  proposal={proposal}
                />
              )}

              <p className="font-light text-[15px] pt-[5px] pb-[20px] text-center text-gray-500">
                {`${votesCountText} with ${totalVotingPower} total votes`}
              </p>
            </div>
          )}

          {state === TTxUiState.TX_SUCCESS && vote && result.data && (
            <div className="max-h-[500px] overflow-auto">
              <div>
                <ProposalVoteResults
                  results={result.data}
                  voteType={vote}
                  formatWeight={
                    collective.governanceType === CollectiveTokenType.OWNED
                  }
                />
              </div>

              <div>
                <div className="flex justify-end p-4">
                  <Button
                    onClick={() => {
                      onSuccessDismiss?.()
                      onClose?.()
                    }}
                    color="lightgray"
                    label={`Dismiss`}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Web3ActionModal>
    </MembershipRequiredModal>
  )
}

function calculateVotingPower(
  memberLists: CollectiveMemberFieldsFragment[],
  selectedDelegateIds: UUID[],
  totalTokens = '0',
  formatWeight = true
) {
  const total = memberLists
    .reduce((acc, current) => {
      const currentUserId = current.user.id
      if (selectedDelegateIds.includes(currentUserId as UUID)) {
        return acc.plus(current.totalTokens)
      }

      return acc
    }, new BigNumber(0))
    .plus(new BigNumber(totalTokens))

  return formatWeight ? total.formatEther(0) : total.toString()
}
