import { CollectiveMemberFieldsFragment, EthNetwork } from 'graphql/generated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobot } from '@fortawesome/pro-light-svg-icons'
import EtherscanLink from './EtherscanLink'
import { UserAvatar } from 'components/common/Avatars'
import Thumbnail from 'components/Thumbnail'

type TPlatformUser = Pick<
  NonNullable<CollectiveMemberFieldsFragment['user']['platformUser']>,
  'id' | 'thumb' | 'displayName'
>

type TUser = Pick<CollectiveMemberFieldsFragment['user'], 'address'> & {
  platformUser?: Maybe<TPlatformUser>
}

export interface IProps {
  user: TUser
  network?: EthNetwork
  limitedView?: boolean
}

function CollectiveUserInfo({
  user,
  limitedView = false,
  network = EthNetwork.MAINNET
}: IProps) {
  if (!user.platformUser) {
    return (
      <div className="flex items-center space-x-2">
        <FontAwesomeIcon
          icon={faRobot}
          className="h-12 w-12 rounded-full overflow-hidden"
        />

        <EtherscanLink
          network={network}
          type={'address'}
          address={user.address}
          shortAddress
        />
      </div>
    )
  }

  return (
    <div className="flex items-center space-x-2">
      {limitedView ? (
        <UserAvatar user={user.platformUser} size={'small'} />
      ) : (
        <Thumbnail
          src={user.platformUser.thumb}
          className="h-16 w-16 rounded-full overflow-hidden"
        />
      )}

      <div>
        <p className="text-gray-900 font-medium pb-0.5 text-sm">
          {user.platformUser.displayName}
        </p>

        <EtherscanLink
          network={network}
          type={'address'}
          address={user.address}
          shortAddress
          className="text-gray-500 font-light text-sm"
        />
      </div>
    </div>
  )
}

export default CollectiveUserInfo
