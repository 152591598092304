import { IMemberTypes } from 'components/Collectives/Demo/common'
import { useCollectiveDemoContext } from 'context/CollectiveDemoContext'
import { VoteType } from 'graphql/generated'
import { useCallback } from 'react'

export const useUpdateProposalDataVotes = () => {
  const { getProposalDataForId, setProposalDataForId } =
    useCollectiveDemoContext()

  const updateProposalDataVotes = useCallback(
    (id: string, forAgainst: VoteType, user: IMemberTypes) => {
      const data = getProposalDataForId(id)

      if (!data) {
        return
      }

      const newData = { ...data }

      if (!newData.votes) {
        newData.votes = {
          __typename: 'CollectiveProposalVoteConnection',
          edges: [],
          pageInfo: {
            __typename: 'PageInfo',
            hasNextPage: false,
            hasPreviousPage: false
          }
        }
      }

      const userMembership = user.membership

      newData.votes.edges = [
        {
          __typename: 'CollectiveProposalVoteEdge',
          node: {
            __typename: 'CollectiveProposalVote',
            id: 'demoVote' + userMembership.id,
            type: forAgainst,
            weight: userMembership.totalTokens,
            member: userMembership,
            signature: 'demoSig',
            signatureUrl: ''
          },
          cursor: ''
        },
        ...newData.votes.edges
      ]

      setProposalDataForId(id, newData)
    },
    [getProposalDataForId, setProposalDataForId]
  )
  return updateProposalDataVotes
}
