import {
  AnswerInput,
  QuestionType,
  Question,
  QuestionChoice,
  QuestionResults
} from 'graphql/generated'
import DatePicker from 'react-datepicker'
import { pull } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarAlt,
  faCheckCircle,
  faCircle
} from '@fortawesome/pro-regular-svg-icons'
import BigNumber from 'bignumber.js'
import moment from 'moment'
import { inflect } from 'inflection'
import Link from 'next/link'
import { Checkbox, Typography } from 'components/common'
import tailwind from 'lib/tailwind'

type TQuestionType = Pick<
  Question,
  'questionType' | 'choices' | 'options' | 'question'
>

const BackgroundFillBox: React.FC<{
  showingResults: boolean
  myAnswerChecked: boolean | undefined
  result: number
  hasResults: boolean
  children?: React.ReactNode
}> = ({ children, showingResults, hasResults, myAnswerChecked, result }) => {
  const negligible = new BigNumber(0).isEqualTo(result)
  return (
    <div
      className={'flex-1 rounded mr-2 py-1 relative flex flex-row items-center'}
    >
      <div
        className={'absolute h-full'}
        style={
          showingResults && hasResults
            ? {
                width: `${negligible ? 0.2 : result}%`,
                backgroundColor: myAnswerChecked
                  ? selectedColor
                  : allResultsColor,
                borderRadius: 4
              }
            : {}
        }
      />
      <div className="ml-2 relative z-1000 w-full">{children}</div>
    </div>
  )
}

export const selectedColor = 'rgba(18,88,208,0.2)'
const allResultsColor = tailwind.theme?.backgroundColor?.['gray'][300]

interface IProps {
  isOnlyQuestion: boolean
  disabled?: boolean
  question: TQuestionType
  questionIndex: number
  answers: Array<AnswerInput>
  setAnswersArr: CallbackWithParam<Array<AnswerInput>>
  alreadyAnswered: boolean
  showingResults: boolean
  questionResult: Maybe<QuestionResults>
  limitedView: boolean
  linkTitleTo?: string
}

export const CollectiveProposalQuestion: React.FC<IProps> = ({
  isOnlyQuestion,
  question,
  questionIndex,
  answers,
  setAnswersArr,
  disabled,
  alreadyAnswered,
  showingResults,
  questionResult,
  limitedView,
  linkTitleTo
}) => {
  const updateCheckbox = (checked: boolean, choice: QuestionChoice) => {
    if (!choice.stringChoice) {
      return
    }

    const newAnswerMap = [...answers]
    const choicesArr = newAnswerMap[questionIndex]?.choices || []
    if (checked) {
      choicesArr.push({
        stringChoice: choice.stringChoice
      })
    } else {
      const matchedChoice = choicesArr.find(
        c => c?.stringChoice === choice.stringChoice
      )
      pull(choicesArr, matchedChoice)
    }

    setAnswersArr(newAnswerMap)
  }

  const updateRadio = (choice: QuestionChoice) => {
    if (!choice.stringChoice) {
      return
    }
    const newAnswerMap = [...answers]
    newAnswerMap[questionIndex].choices = [
      {
        stringChoice: choice.stringChoice
      }
    ]
    setAnswersArr(newAnswerMap)
  }

  const updateDate = (date: Date) => {
    const newAnswerMap = [...answers]
    newAnswerMap[questionIndex].choices = [
      {
        dateChoice: date
      }
    ]

    setAnswersArr(newAnswerMap)
  }

  return (
    <>
      {linkTitleTo ? (
        <Link
          className="text-gray-700 text-sm font-bold pb-1.5 break-all hover:underline"
          href={linkTitleTo}
        >
          {isOnlyQuestion
            ? question.question
            : `${questionIndex + 1}. ${question.question}`}

          {!question.options.required && (
            <span className="text-gray-400 font-normal">{` (Optional)`}</span>
          )}
        </Link>
      ) : (
        <p className="text-gray-700 text-sm font-bold pb-1.5 break-all">
          {isOnlyQuestion
            ? question.question
            : `${questionIndex + 1}. ${question.question}`}

          {!question.options.required && (
            <span className="text-gray-400 font-normal">{` (Optional)`}</span>
          )}
        </p>
      )}

      <div className="space-y-2">
        {question.questionType === QuestionType.CHECKBOX &&
          question.choices?.map((choice: QuestionChoice, i) => {
            const counts =
              questionResult?.datasetPercent[
                questionResult?.labels.findIndex(
                  label => label === choice.stringChoice
                )
              ] || 0

            const result = counts
            const myAnswerChecked = !!answers[questionIndex]?.choices?.find(
              answerChoice => answerChoice?.stringChoice === choice.stringChoice
            )

            return (
              <div
                className="flex justify-between items-center"
                key={`choice_${i}`}
              >
                <BackgroundFillBox
                  result={result}
                  showingResults={showingResults}
                  myAnswerChecked={myAnswerChecked}
                  hasResults={!!questionResult?.totalAnswers}
                >
                  <Checkbox
                    disabled={disabled}
                    color="default"
                    checked={myAnswerChecked}
                    onChange={checked => updateCheckbox(checked, choice)}
                    label={choice.stringChoice ?? undefined}
                  />
                </BackgroundFillBox>

                {showingResults && !!questionResult?.totalAnswers && (
                  <p className="text-gray-600 text-sm font-light">
                    {result.toFixed(1)}%
                  </p>
                )}
              </div>
            )
          })}

        {question.questionType === QuestionType.MULTIPLE_CHOICE &&
          question.choices?.map((choice: QuestionChoice, i) => {
            const result =
              questionResult?.datasetPercent[
                questionResult?.labels.findIndex(
                  label => label === choice.stringChoice
                )
              ] || 0

            const myAnswerChecked = answers[questionIndex]?.choices?.some(
              answerChoice => answerChoice?.stringChoice === choice.stringChoice
            )

            return (
              <div
                className="flex justify-between items-center"
                key={`choice_${i}`}
              >
                <BackgroundFillBox
                  result={result}
                  showingResults={showingResults}
                  myAnswerChecked={myAnswerChecked}
                  hasResults={!!questionResult?.totalAnswers}
                >
                  <div
                    className="w-full flex items-center space-x-4 cursor-pointer"
                    onClick={() => !disabled && updateRadio(choice)}
                  >
                    <FontAwesomeIcon
                      icon={myAnswerChecked ? faCheckCircle : faCircle}
                      className={
                        myAnswerChecked ? 'text-blue-600' : 'text-gray-500'
                      }
                    />

                    <Typography size="sm">{choice.stringChoice}</Typography>
                  </div>
                </BackgroundFillBox>

                {showingResults && !!questionResult?.totalAnswers && (
                  <p className="text-gray-600 text-sm font-light">
                    {result.toFixed(1)}%
                  </p>
                )}
              </div>
            )
          })}

        {question.questionType === QuestionType.DATETIME && (
          <>
            <div className="flex flex-1 items-center gap-2">
              <DatePicker
                disabled={disabled}
                onChange={(date: Date) => {
                  if (date) {
                    updateDate(date)
                  }
                }}
                value={
                  answers[questionIndex].choices?.length
                    ? answers[questionIndex].choices?.[0].dateChoice
                      ? Intl.DateTimeFormat('en-US', {
                          dateStyle: 'long'
                        }).format(
                          answers[questionIndex].choices?.[0].dateChoice ||
                            undefined
                        )
                      : undefined
                    : undefined
                }
                dateFormat="MM/DD/YYYY"
                className="rounded-md border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 w-32 text-sm"
                selected={
                  answers[questionIndex].choices?.length
                    ? answers[questionIndex].choices?.[0]?.dateChoice
                    : undefined
                }
                placeholderText={'MM/DD/YYYY'}
                showTimeSelect={question.options.dateOptions?.includeTime}
              />
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>

            {questionResult?.labels.map((label, i) => {
              const result = questionResult?.datasetPercent[i] || 0
              const myAnswerChecked = !!answers[questionIndex]?.choices?.find(
                answerChoice =>
                  moment(answerChoice?.dateChoice).isSame(
                    moment(label).toDate()
                  )
              )
              return (
                <div
                  className={'flex justify-between items-center'}
                  key={`choice_${i}`}
                >
                  <BackgroundFillBox
                    result={result}
                    showingResults={showingResults}
                    myAnswerChecked={myAnswerChecked}
                    hasResults={!!questionResult?.totalAnswers}
                  >
                    <div className="py-1">
                      <p className="text-gray-800 text-sm font-light break-all">
                        {label}
                      </p>
                    </div>
                  </BackgroundFillBox>

                  {showingResults && !!questionResult?.totalAnswers && (
                    <p className="text-gray-600 text-sm font-light">
                      {result.toFixed(1)}%
                    </p>
                  )}
                </div>
              )
            })}
          </>
        )}
      </div>

      {!limitedView && (
        <div className="mt-4 flex gap-2">
          {showingResults && questionResult && (
            <p className="text-gray-400 text-xs font-light">
              {`${questionResult.totalAnswers} ${inflect(
                'member',
                questionResult.totalAnswers
              )} answered this question`}
            </p>
          )}

          {alreadyAnswered && !answers[questionIndex].choices?.length && (
            <p className="text-gray-400 text-xs font-light">
              {`\u00b7 \u2004You did not answer this question`}
            </p>
          )}
        </div>
      )}
    </>
  )
}
