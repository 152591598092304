import VotingViz, {
  TProposal as TProposalVotingViz,
  TCollective as TCollectiveVotingViz
} from './VotingViz'
import QuorumViz, { TProposal as TProposalQuorumViz } from './QuorumViz'

type TProposal = TProposalVotingViz & TProposalQuorumViz

interface IProps {
  collective: TCollectiveVotingViz
  proposal: TProposal
  variant?: 'narrow' | 'normal'
}

function ProposalTallyViz({
  collective,
  proposal,
  variant = 'normal'
}: IProps) {
  return (
    <div className="divide-y divide-gray-200">
      <VotingViz
        collective={collective}
        proposal={proposal}
        variant={variant}
      />

      {variant === 'normal' && (
        <QuorumViz proposal={proposal} variant={variant} />
      )}
    </div>
  )
}

export default ProposalTallyViz
